import store from "./store";

const { DEV, VITE_APP_BASE_URL, VITE_OPEN_PROXY } = import.meta.env;

const fetchStream = (url: string, params: {
    method?: string;
    headers?: HeadersInit;
    body?: BodyInit;
    onmessage?: (data: any) => void;
    onclose?: () => void;
}) => {
    const { onmessage, onclose, ...otherParams } = params;
    const push = async (controller: ReadableStreamDefaultController<any>, reader: ReadableStreamDefaultReader<Uint8Array> | undefined) => {
        const { value, done } = await reader!.read();
        const decoder = new TextDecoder('utf-8');
        if (done) {
            controller.close();
            onclose?.();
        } else {
            onmessage?.(decoder.decode(value));
            controller.enqueue(value);
            push(controller, reader);
        }
    };


    // 发送请求
    const base = DEV && VITE_OPEN_PROXY === "true" ? "/proxy/" : VITE_APP_BASE_URL
    return fetch(base + url, {
        ...otherParams,
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: store.getItem("token")!
        },
    })
        .then((response) => {
            // 以ReadableStream解析数据
            const reader = response.body?.getReader();
            const stream = new ReadableStream({
                start(controller) {
                    push(controller, reader);
                },
            });
            return stream;
        })
        .then((stream) => new Response(stream, { headers: { 'Content-Type': 'text/html' } }).text());
};

export default fetchStream