import { Suspense, lazy } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import AppLayout from "@/components/Layout";

const FallBack: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <Suspense fallback={<div>Loading...</div>}>{children}</Suspense>;
};

const Home = lazy(() => import("@/pages/home/index"));

const Routes = createBrowserRouter([
  // {
  //   path: "/",
  //   element: <AppLayout />,
  //   children: [
  //     {
  //       path: "/",
  //       element: (
  //         <FallBack>
  //           <Home />
  //         </FallBack>
  //       ),
  //     },
  //     {
  //       path: "/topic/:id",
  //       lazy: async () => ({
  //         Component: (await import("@/pages/topic/index")).default,
  //       }),
  //     },
  //     {
  //       path: "/category/:id",
  //       lazy: async () => ({
  //         Component: (await import("@/pages/category/index")).default,
  //       }),
  //     },
  //     {
  //       path: "/about",
  //       lazy: async () => ({
  //         Component: (await import("@/pages/about/index")).default,
  //       }),
  //     },
  //   ],
  // },
 
  {
    path: "/",
    lazy: async () => ({
      Component: (await import("@/pages/chat/index")).default,
    }),
  },
  {
    path: "/:id",
    lazy: async () => ({
      Component: (await import("@/pages/chat/index")).default,
    }),
  },
]);

export const AppRouter = () => {
  return <RouterProvider router={Routes} />;
};
