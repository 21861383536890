import React, { useState } from "react";
import {
  Button,
  Dialog,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Input,
  Checkbox,
  Switch,
} from "@material-tailwind/react";
import { useHttp } from "@/hooks/useFetch";
import { useGlobalData } from "../Layout/GlobalContext";
import { XCircleIcon } from "@heroicons/react/24/outline";

export function LoginDialog({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: () => void;
}) {
  const { Http } = useHttp();
  const { setLoginModal, setRegisterModal, login, alert } = useGlobalData();

  const [phone, setphone] = useState("");
  const [password, setPassword] = useState("");
  const [submit, setSubmit] = useState(false);

  const handleCloseClick = () => {
    handleClose && handleClose();
  };

  const handleLogin = async () => {
    setSubmit(true);
    if (!phone || !password) return;
    await login(phone, password);

    alert({
      color: "green",
      message: "登录成功",
    });

    setLoginModal(false);
  };

  return (
    <>
      <Dialog
        size="xs"
        open={open}
        handler={() => setLoginModal(true)}
        className="bg-transparent shadow-none"
      >
        <Card className="mx-auto w-full max-w-[24rem]">
          <CardBody className="flex flex-col gap-4">
            <XCircleIcon
              className="absolute w-7 right-5 top-5 cursor-pointer"
              onClick={handleCloseClick}
            />
            <Typography variant="h4" color="blue-gray">
              登录
            </Typography>
            <Typography
              className="mb-3 font-normal"
              variant="paragraph"
              color="gray"
            >
              请输入你的手机号和密码进行登录
            </Typography>
            <Typography className="-mb-2" variant="h6">
              手机号
            </Typography>
            <Input
              error={submit && !phone}
              onChange={({ target }) => setphone(target.value)}
              crossOrigin=""
              label="Phone"
              autoComplete="off"
              size="lg"
            />
            <Typography className="-mb-2" variant="h6">
              密码
            </Typography>
            <Input
              error={submit && !password}
              type="password"
              autoComplete="new-password"
              onChange={({ target }) => setPassword(target.value)}
              crossOrigin=""
              label="Password"
              size="lg"
            />
            <div className="ml-1">
              <Switch crossOrigin="" label="记住密码" />
            </div>
          </CardBody>
          <CardFooter className="pt-0">
            <Button variant="gradient" onClick={() => handleLogin()} fullWidth>
              登录
            </Button>
            <Typography variant="small" className="mt-4 flex justify-center">
              还没有账号？
              <Typography
                as="a"
                variant="small"
                color="blue-gray"
                className="ml-1 font-bold"
                onClick={() => {
                  handleCloseClick();
                  setRegisterModal(true);
                }}
              >
                注册
              </Typography>
            </Typography>
          </CardFooter>
        </Card>
      </Dialog>
    </>
  );
}
